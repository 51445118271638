import React, { useEffect, useState } from 'react'
import './home.scss'
import {
  Dropdown,
  Menu,
} from 'antd'
import {
  FileTextOutlined,
  FileDoneOutlined,
} from '@ant-design/icons'
import { Routes, Route, Link, Navigate, useNavigate, useLocation } from 'react-router-dom';
import ApplyManager from './ApplyManager/index'
import Appointment from './Appointment/index'
import LOGO_WHITE from '../../images/logo_white.png'
import USER_ICON from '../../images/user_icon.svg'
import QUIT_ICON from '../../images/quit.svg'
import { Fetch } from '../../utils/fetchData';

const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState('1');

  useEffect(() => {
    if (location.pathname.includes('appointment')) {
      setSelectedKey('2');
    } else if (location.pathname.includes('apply')) {
      setSelectedKey('1');
    }
  }, [location.pathname]);

  const handleLogout = () => {
    Fetch.GET('/api/logout', {}).then(res => {
      if(res.code === 200) {
        navigate('/login');
      }
    })
  }

  const dropdownMenuItems = [
    {
      key: '1',
      label: (
        <div className="navigator-item">
          <img src={USER_ICON} className="navigator-user-icon"/>
          <span>{'HUQAdmin'}</span>
        </div>
      ),
    },
    {
      key: '2',
      label: (
        <div className="navigator-item" onClick={handleLogout}>
          <img src={QUIT_ICON} className="navigator-icon"/>
          <span>退出</span>
        </div>
      ),
    },
  ]

  const menuItems = [
    { key: '1', label: '入学申请管理', icon: <FileTextOutlined/> },
    { key: '2', label: '入校登记管理', icon: <FileDoneOutlined/> },
  ]

  const handleMenuClick = (e) => {
    switch (e.key) {
      case '1':
        navigate('apply');
        break;
      case '2':
        navigate('appointment');
        break;
      default:
        console.log('no navigate')
    }
  }

  return (
    <div className="home-wrap">
      <div className="navigator">
        <img src={LOGO_WHITE} className="logo-left"/>
        <div className="navigator-right">
          <Dropdown menu={{ items: dropdownMenuItems }}
                    overlayClassName="navigator-dropdown-menu"
                    placement="bottomRight">
            <img src={USER_ICON} className="navigator-user-icon"/>
          </Dropdown>
        </div>
      </div>
      <div className="content">
        <div className="left-part">
          <Menu
            onClick={handleMenuClick}
            className="left-menu"
            style={{ width: 190 }}
            selectedKeys={[selectedKey]}
            mode="inline"
            items={menuItems}
          />
        </div>
        <div className="right-part">
          <Routes>
            <Route path="/" element={<Navigate to="apply" replace/>}/>
            <Route path="apply" element={<ApplyManager/>}/>
            <Route path="appointment" element={<Appointment/>}/>
          </Routes>
        </div>
      </div>
    </div>
  )
}

export default Home;
