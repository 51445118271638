import React, { useEffect } from 'react';
import { Route, Link, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import Login from './Pages/Login'
import Home from './Pages/Home'
import './MainLayout.scss'


function MainLayout() {

  return <Routes>
    <Route path="/" element={<Navigate to="/login" replace/>}/>
    <Route path="/login" element={<Login/>}/>
    <Route path="/home/*" element={<Home/>}/>
  </Routes>
}

export default MainLayout;
