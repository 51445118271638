import React, { useEffect, useState } from 'react'
import './detailDrawer.scss'
import { Drawer } from 'antd'
import ContactComp from './ContactComp'

const DetailDrawer = ({ record, clearData }) => {

  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (record) {
      setOpen(true)
    }
  }, [JSON.stringify(record)])

  const toggleDrawer = () => {
    setOpen(false)
    clearData && clearData()
  }

  if (!record) return null

  const renderDetail = (label, value) => (
    <div className="detail-row">
      <label className="detail-label">{label}:</label>
      <span className="detail-value">{value}</span>
    </div>
  )

  return (
    <Drawer
      title="详情"
      placement="right"
      onClose={toggleDrawer}
      open={open}
      width={500}
      className="detailDrawer-wrap"
    >
      <h2>个人信息</h2>
      {renderDetail("姓名", record.student_name)}
      {renderDetail("性别", record.gender)}
      {renderDetail("民族", record.ethnicity)}
      {renderDetail("出生日期", record.date_of_birth)}
      {renderDetail("籍贯", record.place_of_origin)}
      {renderDetail("户口所在地", record.residence)}
      {renderDetail("证件类型", record.id_type)}
      {renderDetail("证件号", record.id_number)}
      {renderDetail("手机号", record.phone)}
      {renderDetail("邮箱", record.email)}
      {renderDetail("邮寄通知书地址", record.mailing_address)}
      {renderDetail("邮寄联系人", record.mailing_contact)}
      {renderDetail("邮寄联系电话", record.mailing_contact_phone)}

      <h2>学习经历</h2>
      {renderDetail("学历", record.education)}
      {renderDetail("就读学校", record.school)}
      {renderDetail("毕业时间", record.graduation_date)}
      {renderDetail("外语成绩", record.foreign_language_score)}

      <h2>专业选择</h2>
      {renderDetail("报考专业类别", record.major_category)}

      <h2>家庭情况</h2>
      <div className="detail-row">
        <label className="detail-label">家庭紧急联系人:</label>
        <div className="detail-value">
          <ContactComp value={record.family_contacts} />
        </div>
      </div>

      {renderDetail("国际项目中心联系老师", record.international_project_center_contact)}
    </Drawer>
  )
}

export default DetailDrawer
