import './contactComp.scss'

const ContactComp = ({value}) => {
  if (!value?.length) return null
  return (<div className="contact_wrap">
    {value?.map((el, idx) => (<div className="card" key={idx}>
      <div className="card-left">
        <p >{`${el?.name}`}</p>
        {el.relationship ? <p>{`关系：${el.relationship}`}</p> : <></>}
        <p>{`电话：${el?.phone}`}</p>
        <p>{`单位：${el?.company}`}</p>
        <p>{`职务：${el?.position}`}</p>
      </div>
    </div>))}
  </div>)
}
export default ContactComp