const { sleep } = require('./util.js');

export const Fetch = {
  async GET(url, params = {}) {
    const queryStringComponents = [];
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const value = typeof params[key] === 'object' ? JSON.stringify(params[key]) : params[key];
        queryStringComponents.push(`${key}=${encodeURIComponent(value)}`);
      }
    }

    const fullUrl = `${url}?${queryStringComponents.join('&')}`;

    const response = await fetch(fullUrl);
    const data = await response.json();
    return data;
  },

  async POST(url, params = {}, body = {}) {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    });

    return await response.json();
  },

  async PUT(url, params = {}, body = {}) {
    const queryStringComponents = [];
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const value = typeof params[key] === 'object' ? JSON.stringify(params[key]) : params[key];
        queryStringComponents.push(`${key}=${encodeURIComponent(value)}`);
      }
    }

    const fullUrl = `${url}?${queryStringComponents.join('&')}`;

    const response = await fetch(fullUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body)
    });

    return await response.json();
  },

  async DELETE(url, params = {}) {
    const queryStringComponents = [];
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const value = typeof params[key] === 'object' ? JSON.stringify(params[key]) : params[key];
        queryStringComponents.push(`${key}=${encodeURIComponent(value)}`);
      }
    }

    const fullUrl = `${url}?${queryStringComponents.join('&')}`;
    const response = await fetch(fullUrl, {
      method: 'DELETE'
    });

    const data = await response.json();

    return data;
  }
};
