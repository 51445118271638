import React, {useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import './login.scss'
import {
  Form,
  Input,
  Button,
  notification
} from 'antd'
import LOGO_WHITE from '../../images/logo_white.png'
import LOGO from '../../images/logo.png'
import PIC from '../../images/img.png'
import { Fetch } from '../../utils/fetchData'

const Login = () => {
  const navigate = useNavigate()
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (placement, text) => {
    api.error({
      message: `${text}`,
      placement,
    });
  };

  const handleLogin = (values) => {
    Fetch.POST('/api/login',{},{
      username: values.username,
      password: values.password
    }).then(res => {
      if(res.code !== 401) {
        navigate('/home')
      }
      openNotification('topRight', res.message)
    })
  }
  useEffect(() => {
    Fetch.GET('/api/status').then(res => {
      if(res.code !== 401) {
        navigate('/home')
      }
    })
  })

  return <div className="login-wrap">
    {contextHolder}
    <div className="left">
      <img src={LOGO_WHITE} className="logo-left"/>
      <img src={PIC} className="background"/>
    </div>
    <div className="right">
      <img src={LOGO} className="logo"/>
      <div className="form">
        <Form className="login-form" onFinish={handleLogin}>
          <Form.Item
            name="username"
            rules={[{ required: true, message: '请输入用户名' }]}>
            <Input maxLength={50} placeholder="请输入用户名" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: '请输入密码' }]}>
            <Input maxLength={50} type="password" placeholder="请输入密码"/>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="login-form-button">
              登录
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  </div>
}

export default Login
