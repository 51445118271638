import { Button, Tag } from 'antd';
import React from 'react';

export const columns = [
  {
    title: '申请ID',
    dataIndex: 'id',
    key: 'id',
    width: 80
  },
  {
    title: '姓名',
    dataIndex: 'student_name',
    key: 'student_name',
    width: 120,
  },
  {
    title: '性别',
    dataIndex: 'gender',
    key: 'gender',
    width: 80,
  },
  {
    title: '录取状态',
    key: 'admission_status',
    width: 120,
    render: (line) => (
      <div className="actions-cell">
        {line.admission_status === 0
          ? <Tag color="gold">待审批</Tag>
          : line.admission_status === 1
            ? <Tag color="green">已录取</Tag>
            : <Tag color="red">已回绝</Tag>
        }
      </div>
    ),
  },
  {
    title: '民族',
    dataIndex: 'ethnicity',
    key: 'ethnicity',
    width: 100,
  },
  {
    title: '出生日期',
    dataIndex: 'date_of_birth',
    key: 'date_of_birth',
    width: 120,
  },
  {
    title: '籍贯',
    dataIndex: 'place_of_origin',
    key: 'place_of_origin',
    width: 80,
  },
  {
    title: '户口所在地',
    dataIndex: 'residence',
    key: 'residence',
    width: 200,
  },
  {
    title: '证件类型',
    dataIndex: 'id_type',
    key: 'id_type',
    width: 100,
  },
  {
    title: '证件号',
    dataIndex: 'id_number',
    key: 'id_number',
    width: 200,
  },
  {
    title: '手机号',
    dataIndex: 'phone',
    key: 'phone',
    width: 150,
  },
  {
    title: '邮箱',
    dataIndex: 'email',
    key: 'email',
    width: 210,
  },
  {
    title: '邮寄通知书地址',
    dataIndex: 'mailing_address',
    key: 'mailing_address',
    width: 200,
  },
  {
    title: '邮寄联系人',
    dataIndex: 'mailing_contact',
    key: 'mailing_contact',
    width: 120,
  },
  {
    title: '邮寄联系电话',
    dataIndex: 'mailing_contact_phone',
    key: 'mailing_contact_phone',
    width: 150,
  },
  {
    title: '学历',
    dataIndex: 'education',
    key: 'education',
    width: 120,
  },
  {
    title: '就读学校',
    dataIndex: 'school',
    key: 'school',
    width: 200,
  },
  {
    title: '毕业时间',
    dataIndex: 'graduation_date',
    key: 'graduation_date',
    width: 120,
  },
  {
    title: '外语成绩',
    dataIndex: 'foreign_language_score',
    key: 'foreign_language_score',
    width: 100,
  },
  {
    title: '报考专业类别',
    dataIndex: 'major_category',
    key: 'major_category',
    width: 120,
  },
  {
    title: '国际项目中心联系老师',
    dataIndex: 'international_project_center_contact',
    key: 'international_project_center_contact',
    width: 200,
  },
  // {
  //   title: '家庭联系人ID',
  //   dataIndex: 'family_contact_id',
  //   key: 'family_contact_id',
  // },
];
