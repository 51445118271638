import { Tag } from 'antd';
import React from 'react';

export const columns = [
  {
    title: '预约ID',
    dataIndex: 'id',
    key: 'id',
    width: 80,
  },
  {
    title: '姓名',
    dataIndex: 'name',
    key: 'name',
    width: 120,
  },
  {
    title: '手机号',
    dataIndex: 'phone',
    key: 'phone',
    width: 150,
  },
  {
    title: '审批状态',
    key: 'approval_status',
    width: 120,
    render: (line) => (
      <div className="actions-cell">
        {line.approval_status === 0
          ? <Tag color="gold">待审批</Tag>
          : line.approval_status === 1
            ? <Tag color="green">已通过</Tag>
            : <Tag color="red">已回绝</Tag>
        }
      </div>
    ),
  },
  {
    title: '性别',
    dataIndex: 'gender',
    key: 'gender',
    width: 80,
  },
  {
    title: '身份证号',
    dataIndex: 'id_number',
    key: 'id_number',
    width: 200,
  },
  {
    title: '现居住地',
    dataIndex: 'current_residence',
    key: 'current_residence',
    width: 200,
  },
  {
    title: '预约日期',
    dataIndex: 'appointment_date',
    key: 'appointment_date',
    width: 120,
  },
];
