import React, { useState, useEffect } from 'react';
import './appointment.scss';
import {
  Table,
  Input,
  Button,
  Select,
  Form,
  Row,
  Col,
  DatePicker,
  message
} from 'antd';
import { columns } from './columns'; // 定义表格列配置
import { Fetch } from '../../../utils/fetchData';

const AppointmentManager = () => {
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 1 });
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    fetchData({ pagination });
  }, []);

  const fetchData = async (params = {}) => {
    setLoading(true);
    let { current, pageSize } = params.pagination;
    const values = form.getFieldsValue();
    let query = { page: current, limit: pageSize };

    // 只包括有效的查询参数
    if (values.name) {
      query.name = values.name;
    }
    if (values.phone) {
      query.phone = values.phone;
    }
    if (values.approval_status && values.approval_status !== 'all') {
      query.approval_status = values.approval_status;
    }
    if (values.appointment_date) {
      query.appointment_date = values.appointment_date.format('YYYY-MM-DD');
    }

    let res = await Fetch.GET('/api/appointments', query);
    setLoading(false);
    setData(res.data);
    setPagination({
      ...params.pagination,
      total: res.pagination.total,
    });
  };

  const handleTableChange = (pagination) => {
    fetchData({ pagination });
  };

  const handleSearch = () => {
    fetchData({
      pagination: { ...pagination, current: 1 },
    });
  };

  const handleUpdateStatus = async (id, status) => {
    try {
      await Fetch.PUT(`/api/appointments/${id}/status`, {}, { status });
      message.success('状态更新成功');
      fetchData({ pagination });
    } catch (error) {
      message.error('状态更新失败');
    }
  };

  const handleDelete = async (id) => {
    try {
      await Fetch.DELETE(`/api/appointments/${id}`);
      message.success('删除成功');
      fetchData({ pagination });
    } catch (error) {
      message.error('删除失败');
    }
  };

  console.log(data)
  return (
    <div className="applyManager-wrap">
      <div className="title">入校登记管理</div>
      <Form
        form={form}
        layout="inline"
        style={{ marginBottom: 16 }}
        initialValues={{ approval_status: 'all' }}
      >
        <Row gutter={16}>
          <Col>
            <Form.Item label="姓名" name="name">
              <Input placeholder="输入姓名" />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="手机号" name="phone">
              <Input placeholder="输入手机号" />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="状态" name="approval_status">
              <Select
                style={{ width: 120 }}
                options={[
                  { value: 'all', label: '全部' },
                  { value: '0', label: '待审批' },
                  { value: '1', label: '已通过' },
                  { value: '2', label: '已回绝' },
                ]}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="预约日期" name="appointment_date">
              <DatePicker />
            </Form.Item>
          </Col>
          <Col>
            <Button type="primary" onClick={handleSearch}>搜索</Button>
          </Col>
        </Row>
      </Form>
      <Table
        columns={[
          ...columns,
          {
            title: '操作',
            key: 'operation',
            fixed: 'right',
            width: 310,
            render: (line) => (
              <div className="actions-cell">
                {line?.approval_status === 0 ? (
                  <>
                    <Button type="link" className="success" onClick={() => handleUpdateStatus(line.id, 1)}>通过</Button>
                    <Button type="link" className="cancel" onClick={() => handleUpdateStatus(line.id, 2)}>回绝</Button>
                  </>
                ) : <Button type="link" className="cancel" onClick={() => handleUpdateStatus(line.id, 0)}>撤销审批</Button>}
                <Button type="link" className="danger" onClick={() => handleDelete(line.id)}>删除</Button>
              </div>
            ),
          },
        ]}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={pagination}
        loading={loading}
        scroll={{ x: 1500, y: 1800 }}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default AppointmentManager;
